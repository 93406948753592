import React from 'react'
import {Breadcrumb} from '../../components/breadcrumb'
import Layout from '../../components/layout'
import Seo from '../../components/seo'

const TogetherMain = () => {
  const infoSection = (
    <div className="container">
      <div className="row animated fadeInUp delay1">
        <div className="col-md-5">
          <h1>Together</h1>
        </div>
      </div>
    </div>
  )

  return (
    <Layout infoSection={infoSection}>
      <Seo title="Together" />
      <Breadcrumb crumbs={[{label: 'Together'}]} />
      <section className="content_info">
        <div className="row wow fadeInUp">
          <div className="vertical_line">
            <div className="circle_bottom"></div>
          </div>
          <div className="post">
            <h2>
              Unser Herzenzprojekt - <span> Together </span>
            </h2>
            <img src="/img/apps/together/flutter.webp" alt="flutter" />
            <p>
              Mit Moinsen Together wollen wir ein Reihe von (Flutter)-Apps
              entwickeln, die darauf ausgerichtet sind, zusammen und als Gruppe
              Zeit zu verbringen. Damit meinen wir nicht einen klassischen
              Multiplayer, bei dem Menschen aus der ganzen Welt digital zusammen
              kommen, sondern eine Anwendung die physische Nähe erfordert. Das
              kann ein Abend bei Freunden sein, aber auch eine
              Geschäftsveranstalltung, ein Ereigniss in einer Bar oder einem
              Club. Es gibt viele Anwendungsbereiche für die wir mit unserem
              Konzept erschließen können.
              <br />
              Moinsen Together wird eine Art App-Baukasten sein, der es uns
              ermöglicht, auf einfache Art und Weise, weitere Apps zu
              entwicklen.
            </p>
            <img src="/img/apps/together/youtube.webp" alt="youtube" />
            <p>
              Unser Prof-of-Concept ist bereits in Arbeit und wird in absebarer
              Zeit als Anwendung in die Appstores gebracht werden. Wir
              entwickeln einen gemeinschaftlichen Youtube-Player, welcher
              Zentral auf einem großen Bildschrim (ein Fernseher oder
              Beamer-Bild) ausgespielt wird. Allerdings kann jeder Teilnehmer
              über sein Smartphone weitere Videos hinzufügen. Die Hauptanwendung
              arbeitet nach und nach jeden Teilnehmer ab und spielt das nächste
              Video des Teilnehmers ab.
            </p>
            <img />
            <img src="/img/apps/together/together.webp" alt="together" />
            <p>
              Aber hier hört es nicht auf. Wir haben bereits über weitere
              Varianten auf dem selben Tech-Stack nachgedacht. In Planung sind
              bereits eine Quiz-App, ein Tool für Umfragen und eine weitere
              Socializing-Anwendung. <br />
              Nachdem unsere Video-App die Stores erreicht hat, werden wir
              gleich mit der Quiz-App als Stufe 2 unserer Produkt-Palette
              beginnen. Aufbauend auf unserer Software und den Ergebnissen wird
              diese Anwendung noch Stärker auf ein gemeinsames Erlebniss setzten
              und für diverse Gruppen geeignet sein. Von Familien, über
              Teenager-Gruppen zu Schulklassen soll jeder seinen Platz finden.
            </p>
            <img src="/img/apps/together/lego.webp" alt="lego" />
            <p>
              Wir haben viel vor und brauchen Verstärkung. Wir würden uns sehr
              darüber freuen, wenn interessierte Flutter-Entwickler, Designer,
              Grafiker und Business-Developer unser Team bereichern. Wenn ihr
              interesse habt, nehmt gerne mit uns Kontakt über unsere Profile
              auf den diversen sozialen Netzwerken auf oder schreibt uns eine
              Mail an:{' '}
              <a href="mailto:business@moinsen.dev">business@moinsen.dev</a>.
              <br />
              Investoren dürfen sich ebenfalls melden. Dagegen haben wir nichts.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TogetherMain
